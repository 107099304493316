import isEmptyObject from 'is-empty-object'
import Router from 'next/router'

import { extractProductsFromMenu } from '@bff/middleware/extract-products'
import { CatalogItem } from '@bff/models/og/catalog'
import { DEFAULT_MENU_SLUG } from '@helpers/constants'
import { extractBrandsFromProducts } from '@helpers/extract-brands'
import { track } from 'analytics'
import api from 'api'
import { getClientSideBrandCatalog } from 'bff/handlers/brands/client'
import getClientSideGroup from 'bff/handlers/groups/slug/client'
import getClientSideMenu from 'bff/handlers/menus/slug/client'
import getClientSideProduct from 'bff/handlers/products/client'
import errorHandler from 'helpers/error-handler'
import ROUTES from 'helpers/routes'
import { productLoaded, productLoading } from 'redux/loading/actions'
import loading from 'redux/loading/actionTypes'
import { removeActiveAddress } from 'redux/location/actions'
import { setFuseBrands, setFuseGroups, setFuseProducts, setSearchLoading } from 'redux/search/actions'

import t from './actionTypes'

import { getMenuPayload } from '../analytics/get-payloads'
import { setProducts } from '../products/actions'

// searches existing menu store for matching catalog item or requests it
export function findOrRequestCatalogItem(catalogItemId: string) {
  return (dispatch, getState) => {
    dispatch(productLoading())
    const state = getState()
    const storedCatalogItem = state.menu.catalogItem

    // check if catalog item information is already in store
    // mismatch would be in the case where a different catalog item was viewed
    if (storedCatalogItem.id === catalogItemId) {
      dispatch(setCatalogItem(storedCatalogItem))
      return
    }

    // catalog item is either null or has a different id
    if (isEmptyObject(storedCatalogItem)) {
      // if catalogItem is empty, go ahead and request the product
      dispatch(requestCatalogItem(catalogItemId))
    }
  }
}

// searches existing menu store for matching product or requests it
export function findOrRequestProduct(id) {
  return (dispatch, getState) => {
    dispatch(productLoading())
    const state = getState()
    const storedProduct = state.menu.product
    const storedProducts = state.products.collection

    // check if product information is already in store
    // mismatch would be in the case where a different product was viewed
    if (storedProduct.id === id) {
      dispatch(setProduct(storedProduct))
      return
    }

    // product is either null or has a different id
    if (isEmptyObject(storedProducts)) {
      // if products array is empty, go ahead and request the product
      dispatch(requestProduct(id))
    } else {
      // check if product information is contained within the products list
      const matchingProduct = storedProducts[id]
      // if a matching product is found, set it as state.menu.product
      if (matchingProduct) {
        dispatch(setProduct(matchingProduct))
      } else {
        // no match was found. request the product information.
        dispatch(requestProduct(id))
      }
    }
  }
}

export function setGroups(groups) {
  return {
    type: t.SET_GROUPS,
    payload: groups
  }
}

export function setMenus(menus) {
  return {
    type: t.SET_MENUS,
    payload: menus
  }
}

// requests single product
export function requestProduct(productId: number) {
  return async (dispatch, getState) => {
    if (!productId) return // can't get a product without a productId!

    dispatch({ type: t.REQUEST_PRODUCT })

    const state = getState()
    const placeId = state?.location?.activeLocation?.id
    const menuSlug = DEFAULT_MENU_SLUG

    const { err, data: product } = await getClientSideProduct({ productId, placeId, menuSlug })

    if (err) {
      errorHandler(new Error(`Error requesting product: ${err.message}`))
    } else {
      dispatch(setProduct(product))
    }
  }
}

// requests the menu
type RequestMenu = {
  slug: string
  locationId?: string
  onMenuError?: (dispatch, menuError: { message }) => void
  afterAll?: (getState) => void
}
function requestMenu({ slug, locationId, onMenuError, afterAll }: RequestMenu) {
  return async (dispatch, getState) => {
    const state = getState()

    const menuSlug = slug || DEFAULT_MENU_SLUG

    dispatch(setSearchLoading(true))

    const placeId = locationId || state.location.activeLocation.id

    dispatch({ type: loading.MENU_PRODUCTS_LOADING })

    const body: { slug: string; placeId?: string } = {
      slug: menuSlug
    }

    // if we have a place id, request that, otherwise we'll request the sample menu
    if (placeId) {
      body.placeId = placeId
    }

    const { err: menuError, data: menuData } = await getClientSideMenu(body)

    // Sentry capturing object as empty {}
    // normal response is menuError = null
    // only throw an error if we have a property to access
    if (!isEmptyObject(menuError) && menuError !== null) {
      if (onMenuError) {
        onMenuError(dispatch, menuError)
      } else {
        errorHandler(new Error(`Error fetching client side menu: ${menuError?.message}`), body)
      }
      return
    }

    dispatch({ type: loading.MENU_PRODUCTS_LOADED })
    dispatch(setAnnouncements(menuData?.promos))
    dispatch(setMenus(menuData))

    const [products] = extractProductsFromMenu(menuData)

    let brands = []

    if (placeId) {
      brands = extractBrandsFromProducts(products)
    } else {
      const { err: brandsCatalogError, data: brandsCatalog } = await getClientSideBrandCatalog()

      if (!isEmptyObject(brandsCatalogError) && brandsCatalogError !== null) {
        errorHandler(new Error(`Error fetching client side brand catalog: ${brandsCatalogError?.message}`), body)
      }

      brands = brandsCatalog
    }

    dispatch(setProducts(products))
    dispatch(setSearchLoading(false))
    dispatch(setFuseGroups())
    dispatch(setFuseProducts(products))
    dispatch(setFuseBrands(brands))

    if (afterAll) {
      afterAll(getState)
    }
  }
}
export function requestProducts(slug?: string, locationId?: string) {
  const onMenuError = (dispatch, menuError) => {
    if (menuError?.message?.match(/NOT_FOUND/)) {
      dispatch(removeActiveAddress())
      Router.replace(ROUTES.ROOT)
      return errorHandler(new Error(`Error: product not found: ${menuError?.message}`))
    }
    errorHandler(new Error(`Error requesting products: ${menuError?.message}`))
  }

  const afterAll = (getState) => {
    track('Catalog.Load', getMenuPayload(getState()))
  }

  return requestMenu({ slug, locationId, onMenuError, afterAll })
}

export function trackCatalogLoad(slug) {
  return (dispatch, getState) => {
    track('Catalog.Load', getMenuPayload(getState(), slug))
  }
}

// requests the menu for search on non-menu pages
export function requestSearchProducts(slug, locationId) {
  return requestMenu({ slug, locationId })
}

// clear menu product when exiting from single product view
export function clearProduct() {
  return (dispatch) => {
    dispatch(setProduct({}))
  }
}

// sets single product in menu store, used in #requestProduct
export function setProduct(product) {
  return (dispatch) => {
    dispatch(productLoaded())

    // fetch and set the catalog item for this product for social share icons
    if (product.catalogItemId) {
      dispatch(requestCatalogItem(product.catalogItemId))
    } else {
      dispatch(clearCatalogItem())
    }

    dispatch({
      type: t.SET_PRODUCT,
      product
    })
  }
}

export function requestCatalogItem(catalogItemId) {
  return (dispatch) => {
    // Return early if this was invoked without catalogItemId
    if (!catalogItemId) return

    dispatch(productLoading())

    api.getCatalogItem({ id: catalogItemId.toLowerCase() }, (err, res) => {
      if (err) return errorHandler(new Error(err.message))

      dispatch(getRelatedProductGroup(res))
    })
  }
}

export function getRelatedProductGroup(catalogItem: CatalogItem) {
  return async (dispatch, getState) => {
    const {
      location: { activeLocation }
    } = getState()

    // TODO: we are hardcoding accessories for now but it should be replaced by type.slug
    const subtype = catalogItem.subtype.slug
    const slug = `accessories-${subtype}`

    if (!activeLocation.id) {
      dispatch(productLoaded())
      return dispatch(setCatalogItem(catalogItem))
    }

    const body = {
      menu: DEFAULT_MENU_SLUG,
      placeId: activeLocation.id,
      slug
    }

    const { err, data: groupData } = await getClientSideGroup(body)

    if (err) {
      if (err.statusCode === 404) {
        console.warn(err.message)
      } else {
        errorHandler(new Error(`Failed to fetch client side group: ${err.message}`))
      }
    } else {
      catalogItem.relatedProductGroup = groupData
    }
    // always set the catalogue item regardless of whether or not it has related products
    dispatch(setCatalogItem(catalogItem))
    dispatch(productLoaded())
  }
}

export function setCatalogItem(catalogItem) {
  return {
    type: t.SET_CATALOG_ITEM,
    catalogItem
  }
}

export function clearCatalogItem() {
  return {
    type: t.CLEAR_CATALOG_ITEM
  }
}

// close sidebar if it's open, and open it if it's closed
export function toggleSidebar() {
  return (dispatch, getState) => {
    const sidebarState = getState().menu.isSidebarOpen

    if (sidebarState) {
      dispatch(closeSidebar())
    } else {
      track('SideNav.View')
      dispatch(openSidebar())
    }
  }
}

// open the sidebar
export function openSidebar() {
  return {
    type: t.OPEN_SIDEBAR
  }
}

// close the sidebar
export function closeSidebar() {
  return {
    type: t.CLOSE_SIDEBAR
  }
}

export function menuView(menuSlug) {
  return (dispatch, getState) => {
    const state = getState()
    track('Menu.View', getMenuPayload(state, menuSlug))
  }
}

// sets array of products in menu store, used in #requestProducts
export function setAnnouncements(announcements) {
  return {
    type: t.SET_ANNOUNCEMENTS,
    announcements
  }
}
